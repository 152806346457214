import heic2any from 'heic2any'

export const convertToJpeg = async (file: File) => {
  const conversionResult = await heic2any({
    blob: file,
    toType: 'image/jpeg',
  })

  const convertedFile = new File([conversionResult] as BlobPart[], `${file.name.split('.')[0]}.jpeg`, { type: 'image/jpeg', lastModified: Date.now() })

  return convertedFile
}
