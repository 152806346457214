export const useUploadImageStore = defineStore('uploadImage', () => {
  const isUploading = ref<boolean>(false)
  const isUploadingError = ref<boolean>(false)
  const files = ref<File[]>([])
  const imageUrl = ref<string | null>(null)
  const uploadedImageUrl = ref<string | null>(null)
  const originalImageWidth = ref<number>()
  const originalImageHeight = ref<number>()
  const newImageWidth = ref<number>()
  const newImageHeight = ref<number>()
  const canvasStore = useCanvasStore()
  const { targetImage } = storeToRefs(canvasStore)

  const setFiles = (newFiles: File[]) => {
    files.value = newFiles
  }

  const calculateNewDimensions = async (
    origWidth: number,
    origHeight: number,
  ) => {
    let newWidth = origWidth
    let newHeight = origHeight
    const aspectRatio = origWidth / origHeight
    if (origWidth === origHeight) {
      const maxDimension: number = 900
      newWidth = maxDimension
      newHeight = maxDimension
    } else if (origWidth > origHeight) {
      const maxWidth: number = 1200
      newWidth = maxWidth
      newHeight = newWidth / aspectRatio
    } else {
      const maxHeight: number = 1200
      newHeight = maxHeight
      newWidth = newHeight * aspectRatio
    }
    newImageWidth.value = Math.round(newWidth)
    newImageHeight.value = Math.round(newHeight)
  }

  const uploadImageToExternal = async (src: string) => {
    const response = await useCustomFetch('/api/upload', {
      method: 'POST',
      body: {
        src,
        isOriginal: true,
      },
    })

    if (response?.data && typeof response?.data?.value === 'string') {
      uploadedImageUrl.value = response?.data?.value
      imageUrl.value = src
    } else {
      isUploadingError.value = true
      throw new Error('元画像が正常にアップロードできませんでした。')
    }
    isUploading.value = false
  }

  const handleFileChange = async () => {
    if (!files.value || files.value.length === 0) {
      throw new Error('元画像が正常にアップロードできませんでした。')
    }

    let file = files.value[0]

    if (file?.type === 'image/heic') {
      file = await convertToJpeg(file)
    }

    if (file) {
      const reader = new FileReader()

      reader.onload = () => {
        isUploading.value = true
        imageUrl.value = ''
        uploadedImageUrl.value = ''
        if (typeof reader.result === 'string') {
          const img = new Image()
          img.src = reader.result
          img.onload = async () => {
            originalImageWidth.value = img.width
            originalImageHeight.value = img.height

            // calculate new dimensions
            await calculateNewDimensions(
              originalImageWidth.value,
              originalImageHeight.value,
            )

            // resize the image using canvas
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = newImageWidth.value
            canvas.height = newImageHeight.value
            ctx.drawImage(img, 0, 0, newImageWidth.value, newImageHeight.value)

            // convert the canvas image back to a data url
            const resizedImageUrl = canvas.toDataURL('image/jpeg')

            // set the resized image url and upload it
            uploadImageToExternal(resizedImageUrl)
          }
        }
      }

      reader.readAsDataURL(file)

      if (targetImage.value) {
        canvasStore.initTargetImage()
      }
    }
  }

  const clearImageSrcOnUpload = () => {
    imageUrl.value = ''
    files.value = []
    isUploadingError.value = false
  }

  return {
    isUploading,
    isUploadingError,
    files,
    imageUrl,
    uploadedImageUrl,
    newImageWidth,
    newImageHeight,
    handleFileChange,
    clearImageSrcOnUpload,
    setFiles,
  }
})
